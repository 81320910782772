import React from 'react';
import { http } from 'data-graphql';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Modal, Header, FormWrapper, Text, Input, Button, Icon } from 'components';

const Wrapper = styled.div`
  .table {
    display: block;
    max-width: 1300px;
    margin: .5em auto;
    padding: 15px 20px;
  }

  .table table {
    width: 100%;
    background: white;
    table-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #CCC;
  }

  .table thead tr {
    background: #6e1406;
    color: white;
  }

  .topInfo {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 1em;
    max-width: 1300px;
    border-radius: 8px;
    border: 1px solid #222;
    padding: .5em;
    text-align: left;
  }
  
  .topInfo span {
    color: #6e1406;
  }

  .topInfoHeader {
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .field input {
    border: 1px solid #222;
    padding: 6px;
  }

  .topInfo > div {
    width: 50%;
    padding: 1em;
  }

  .topInfo > div > div:first-child {
    margin-bottom: .5em;
    font-size: 1.2em;
    font-weight: bold;
  }

  .topInfo .field {
    margin-bottom: .5em;
  }

  tr td {
    margin: 0;
    border: none;
    text-align: center;
  }

  .table tbody td {
    border: 1px solid #CCC;
    color: #6e1406;
  }

  .table th {
    padding: .4em;
    border: 1px solid #333;
  }

  .table tbody td {
    padding: .4em;
  }

  .table td:first-child {
    width: 100px;
    text-align: center;
  }
  
  .editControls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
  }

  .table td:nth-child(2),
  .table th:nth-child(2) {
    width: 450px;
    padding-left: 1em;
    text-align: left;
  }
  
  .table td:nth-child(3),
  .table th:nth-child(3) {
    width: 200px;
    text-align: left;
  }

  .table input {
    border: 1px solid #AAA;
  }

  input[disabled] {
    background-color: #FFE;
  }

  .radios > div {
    margin-bottom: .5em;
  }

  .radios input {
    margin-right: .5em; 
  }

  .updateButton {
    margin-bottom: 2em;
  }


  .editRequestItem {
    text-align: left;
    padding: 4px;
  }

  .editRequestItem span {
    display: block;
    width: 100%;
  }
  
  .editorCommentItem {
    text-align: left;
    padding: 4px;
  }
  
  .track-header-row {
    background-color: #F5F5F5;
    font-weight: bold;
    border-top: 3px solid #e0e0e0;
  }
  
  .track-header-row td {
    padding: 8px 4px;
    vertical-align: middle;
  }
  
  .track-header-row td:first-child {
    text-align: center;
    font-weight: bold;
    color: #555;
  }
  
  .track-header-row td:nth-child(2) {
    font-weight: bold;
    color: #6e1406; /* MTI red color */
  }
  
  .edit-item-row td {
    vertical-align: top;
    padding: 6px 4px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .edit-item-row:last-child td {
    border-bottom: none;
  }
  
  .edit-item-row td:first-child {
    vertical-align: middle;
  }

  .paymentNickname {
    font-size: 1.2em;
    font-weight: bold;
    margin: .5em 0;
    background: #EFEFEF;
    display: block;
    padding: 6px;
    border-radius: 10px;
    max-width: 450px;
    margin-bottom: 2em;
  }

  .editRequestBox, .paymentBox {
    border-radius: 8px;
    border: 1px solid #222;
    padding: .5em;
    text-align: left;
    margin-bottom: 16px;
    max-width: 1300px;
    margin: 16px auto;
  }

  .paymentBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 24px 0 ;
  }

  .paymentBox > div {
    width: 45%;
  }

  .hoursForm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .hoursForm .hoursInput {
    width: 80px;
    padding: 0 10px;
    margin-right: 30px;
  }

  .hoursForm input {
    width: 100%;
    height: 30px;
  }

  .hoursForm input {
    background: #EFEFEF;
  }

  .chargeTitle {
    margin: 2em auto 1em;
  }

  table th.editRequestHeader {
    text-align: left;
    padding-left: 16px;
  }
  
  table th.editorCommentHeader {
    text-align: left;
  }

  .editRequestBox .editsTitle {
    font-weight: bold;
    text-transform: uppercase;
    color: #6e1406;
    display: block;
    text-align: center;
    margin-top: .5em;
  }

  .editorNotesBox {
    padding: 1em;
  }

  .editorNotesBox span {
    color: #6e1406;
    font-weight: bold;
    font-size: 1.2em;
  }

  .editorNotesBox textarea {
    width: 100%;
    min-height: 100px;
    resize: vertical;
    border: 1px solid #222;
    border-radius: 8px;
    padding: 6px;
    display: block;
    margin: 8px 0;
  }

  .chargeModal {
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
    color: black;
  }

  .chargeModalButtons {
    margin-top: 2em;
    display: flex;
    justify-content: center;
  }

  .chargeModalButtons button {
    margin: 0 1em;
  }

  .noTokenDisclaimer div:nth-child(1) {
    font-weight: bold;   
    font-size: 1.2em;
    margin-bottom: .5em;
  }

  .noTokenDisclaimer div:nth-child(1) {
    
  }
`

const rates = {
  '5': 60,
  '2': 120,
}

// Helper function to get the track name from various possible fields
const getTrackName = (edit, index) => {
  if (!edit) return `Track #${index + 1}`;
  
  // Try various possible field names for track name

  if (edit.track_name && typeof edit.track_name === 'string' && edit.track_name.trim()) {
    return edit.track_name.trim();
  }
  
  if (edit.name && typeof edit.name === 'string' && edit.name.trim()) {
    return edit.name.trim();
  }
  
  // If we have a track ID but no name, add the ID
  if (edit.track_id) {
    return `Track ID: ${edit.track_id.substring(0, 8)}...`;
  }
  
  // Default fallback
  return `Track #${index + 1}`;
}

export default class OrgEdits extends React.Component {
  constructor(props) {
    console.log('PROPS ', props)
    super();
    this.state = {
      showChargeModal: false,
      show: {},
      edit: {},
      booking: {},
      update: {},
      hours: 1,
      editingEntry: null,
      editNewEntry: '',
      editorComment: '',
      editorNotes: '',
      messages: [],
      newMessage: '',
    };
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      update: {
        ...this.state.update,
        [e.target.name]: value
      }
    });
  };

  saveEditing = async (ei, index) => {
    // Work with a copy of the edits array
    const editsCopy = [...this.state.edit.edits];
    
    // Safety checks
    if (!editsCopy || !Array.isArray(editsCopy) || ei >= editsCopy.length) {
      return;
    }
    
    // If this is a new edit for a track that didn't have one
    if (!editsCopy[ei].edit || !Array.isArray(editsCopy[ei].edit)) {
      editsCopy[ei].edit = [''];
    }
    
    // Safety check for the index
    if (index >= editsCopy[ei].edit.length) {
      return;
    }

    // Update the edit value
    editsCopy[ei].edit[index] = this.state.editNewEntry;

    // Initialize or update comments
    if (!editsCopy[ei].comments) {
      editsCopy[ei].comments = Array(editsCopy[ei].edit.length).fill('');
    } else if (editsCopy[ei].comments.length <= index) {
      // Extend the comments array if needed
      const newComments = [...editsCopy[ei].comments];
      while (newComments.length <= index) {
        newComments.push('');
      }
      editsCopy[ei].comments = newComments;
    }
    
    editsCopy[ei].comments[index] = this.state.editorComment;
    
    // Update state and exit editing mode
    this.setState({ 
      edit: { ...this.state.edit, edits: editsCopy },
      editingEntry: null // Clear editing state immediately 
    }, () => {
      this.updateTop(); // Save changes to server
    });
  }
  
  addNewEdit = (ei) => {
    // Get a copy of the current edits
    const editsCopy = [...this.state.edit.edits];
    
    // Ensure we're working with an array
    if (!Array.isArray(editsCopy)) {
      return;
    }
    
    // Safety check for index
    if (ei < 0 || ei >= editsCopy.length) {
      return;
    }
    
    // Initialize the edit array if needed
    if (!editsCopy[ei].edit || !Array.isArray(editsCopy[ei].edit)) {
      editsCopy[ei].edit = [''];
    } else if (editsCopy[ei].edit.length === 0) {
      // Handle empty array case
      editsCopy[ei].edit = [''];
    } else if (editsCopy[ei].edit.length === 1 && editsCopy[ei].edit[0] === '') {
      // Keep existing array if it's just an empty placeholder
    } else {
      // Add new empty edit to existing array
      editsCopy[ei].edit.push('');
    }
    
    // Initialize comments array if needed
    if (!editsCopy[ei].comments) {
      editsCopy[ei].comments = Array(editsCopy[ei].edit.length).fill('');
    } else {
      editsCopy[ei].comments.push('');
    }
    
    const newEditingIndex = editsCopy[ei].edit.length - 1;
    
    // Set state and start editing the new entry
    this.setState({
      edit: { 
        ...this.state.edit, 
        edits: editsCopy 
      },
      editingEntry: `${ei}-${newEditingIndex}`,
      editNewEntry: '',
      editorComment: ''
    });
  }

  triggerCheckbox = async (editIndex, index) => {
    const edits = this.state.edit.edits
    if (!edits[editIndex].checkbox) {
      edits[editIndex].checkbox = [].fill(false, 0, edits[editIndex].edit.length);
    }
    edits[editIndex].checkbox[index] = !edits[editIndex].checkbox[index];
    this.setState({ edit: { ...this.state.edit, edits } }, this.updateTop)
  }
  
  // Delete a single edit item
  deleteEdit = async (editIndex, index) => {
    this.setState({ loading: true });
    try {
      // Make a copy of the edit array for the track
      const editsCopy = [...this.state.edit.edits];
      
      // Remove the specific edit item
      if (editsCopy[editIndex].edit && Array.isArray(editsCopy[editIndex].edit)) {
        const newEditArray = [...editsCopy[editIndex].edit];
        newEditArray.splice(index, 1);
        
        // Also update comments if they exist
        if (editsCopy[editIndex].comments && Array.isArray(editsCopy[editIndex].comments)) {
          const newCommentsArray = [...editsCopy[editIndex].comments];
          newCommentsArray.splice(index, 1);
          editsCopy[editIndex].comments = newCommentsArray;
        }
        
        // Update the edit array
        editsCopy[editIndex].edit = newEditArray;
        
        // Update state and save to server
        this.setState({ 
          edit: { ...this.state.edit, edits: editsCopy },
          loading: false
        }, () => {
          this.updateTop();
          toast.success('Edit item deleted successfully');
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      toast.error('Error deleting edit: ' + (error.message || 'Something went wrong'));
    }
  }
  
  // Delete the entire edit request
  deleteEditRequest = async () => {
    this.setState({ loading: true });
    try {
      const response = await http().post('/editing/admin_delete', { 
        id: this.props.match.params.id 
      });
      
      if (response.success) {
        toast.success('Edit request deleted successfully');
        // Redirect back to the edit requests list
        this.props.history.push('/admin/edits');
      } else {
        throw new Error(response.message || 'Failed to delete edit request');
      }
    } catch (error) {
      this.setState({ loading: false });
      toast.error('Error deleting edit request: ' + (error.message || 'Something went wrong'));
    }
  }

  setEditing = (ei, index, edit) => {
    if (!edit || typeof index === 'undefined') {
      return this.setState({ editingEntry: null });
    }
    
    // Make sure we have data to edit
    if (!edit.edit || !Array.isArray(edit.edit) || index >= edit.edit.length) {
      return;
    }
    
    this.setState({
      editingEntry: `${ei}-${index}`,
      editNewEntry: edit.edit[index],
      editorComment: edit.comments ? edit.comments[index] : ''
    });
  }

  updateClient = async () => {
    if (this.state.loading) return
    if (!confirm('Are you sure you want to send an email to the client?')) {
      return
    }
    this.setState({ loading: true });
    await http().post(`/editing/notify`, { id: this.state.edit.id })
      .then(response => {
        if (response.success) return toast.success('Successfully sent email');
        toast.error('Error sending email, ' + (response.message || 'Something went wrong'));
      })
      .catch(error => {
        toast.error('Error sending email, ' + (error.message || 'Something went wrong'));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  // Handle sending a new message from the editor
  handleSendMessage = async () => {
    const { newMessage } = this.state;
    if (!newMessage || newMessage.trim() === '') {
      return;
    }
    
    try {
      // Insert the message into the database via the track_edit_messages table
      await http().post('/editing/notify', {
        id: this.props.match.params.id,
        message: newMessage.trim(),
        is_from_editor: true
      });
      
      // Add the message to the local state for immediate display
      const newMessageObj = {
        id: `temp-${Date.now()}`,
        edit_id: this.props.match.params.id,
        message: newMessage.trim(),
        is_from_editor: true,
        created_at: new Date().toISOString()
      };
      
      this.setState({
        messages: [...this.state.messages, newMessageObj],
        newMessage: ''
      });
      
      // Refresh messages to get the server-generated ID
      const messagesResponse = await http().get(`/editing/messages/${this.props.match.params.id}`);
      if (messagesResponse.success) {
        this.setState({ messages: messagesResponse.messages || [] });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message: ' + (error.message || 'Unknown error'));
    }
  }

  // Format date for message display
  formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } catch (e) {
      return 'Unknown date';
    }
  }
  
  updateTop = async () => {
    this.setState({ loading: true });
    const payload = {
      id: this.props.match.params.id,
      status: this.state.update.status,
      assigned_to: this.state.update.assigned_to,
      edits: this.state.edit.edits,
    };
    
    // Include hours if we're marking it as complete
    if (this.state.update.status === 'complete' && 
        this.state.edit.status !== 'complete' && 
        this.state.hours) {
      payload.hours = parseFloat(this.state.hours);
    }
    
    await http().post(`/editing/update`, payload)
      .then(response => {
        if (response.success) return toast.success('Successfully updated edit');
        toast.error('Error updating, ' + (response.message || 'Something went wrong'));
      })
      .catch(error => {
        toast.error('Error updating, ' + (error.message || 'Something went wrong'));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  triggerCharge = () => {
    const checkboxes = document.querySelectorAll('#editRows [type="checkbox"]');
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) {
        return toast.error('You must check all edits before charging');
      }
    }

    this.setState({ showChargeModal: true })
  }

  performCharge = async () => {
    this.setState({ showChargeModal: false });
    toast.success(`STAGING - simulating charging for ${this.state.hours} hours`);
  }

  getBookingEdits = async id => {
    http()
      .get(`/editing/list?booking_id=${id}`)
      .then(response => {
        if (response.success) {
          // Pre-process edits to ensure they're correctly parsed
          const processedItems = response.items.map(item => {
            // Process edits if they exist and are a string
            if (item.edits && typeof item.edits === 'string') {
              try {
                const parsed = JSON.parse(item.edits);
                item.edits = parsed;
              } catch (e) {
                item.edits = [];
              }
            } else if (Array.isArray(item.edits)) {
              // Keep as is
            } else if (!item.edits) {
              item.edits = [];
            } else {
              try {
                // Try to convert to array if it's not a string but has some value
                item.edits = Array.isArray(item.edits) ? item.edits : [];
              } catch (e) {
                item.edits = [];
              }
            }
            return item;
          });
          
          this.setState({ edits: processedItems });
        }
      })
      .catch(error => {
        console.error('Error fetching booking edits:', error);
      });
  };

  async componentDidMount() {
    try {
      // Fetch the edit details
      const response = await http().get(`/editing/${this.props.match.params.id}`);
      if (!response.success) {
        return toast.error('Failed to retrieve edit');
      }
      
      // Get booking edits immediately to ensure we have previous edit data
      this.getBookingEdits(response.booking.id);

      // Safely handle edits
      let edits = response.edit.edits;
      if (typeof edits === 'string') {
        try {
          edits = JSON.parse(edits);
        } catch (e) {
          console.error('Error parsing edits in componentDidMount:', e);
          edits = [];
        }
      } else if (!edits) {
        edits = [];
      }

      // Sort edits by cue number if they're an array
      if (Array.isArray(edits)) {
        edits.sort((a, b) => 
          (a.cue_number || '').localeCompare(b.cue_number || '')
        );
      }
      
      // Set state with properly formatted edits
      const editWithSortedEdits = {
        ...response.edit,
        edits
      };
      
      this.setState({ 
        ...response, 
        edit: editWithSortedEdits,
        update: editWithSortedEdits
      });
      
      // Fetch messages for this edit
      try {
        const messagesResponse = await http().get(`/editing/messages/${this.props.match.params.id}`);
        if (messagesResponse.success) {
          this.setState({ messages: messagesResponse.messages || [] });
        }
      } catch (messagesError) {
        console.error('Error fetching messages:', messagesError);
        // Don't show an error toast for messages - it's not critical
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  }


  componentDidUpdate(prevProps, prevState) {
    // Check if edit state exists and needs processing
    if (this.state.edit && this.state.edit !== prevState.edit) {
      this.ensureEditsFormat();
    }
  }

  ensureEditsFormat = () => {
    // Ensure edits exist and are an array
    const { edit } = this.state;
    if (!edit) return;

    let edits = edit.edits;
    let needsUpdate = false;

    if (!edits) {
      edits = [];
      needsUpdate = true;
    } else if (typeof edits === 'string') {
      try {
        edits = JSON.parse(edits);
        needsUpdate = true;
      } catch (e) {
        console.error('Error parsing edits:', e);
        edits = [];
        needsUpdate = true;
      }
    }

    if (needsUpdate) {
      this.setState({
        edit: {
          ...edit,
          edits
        }
      });
    }
  }

  render() {
    if (!this.state.edit || !this.state.booking) return null;

    const subtotal = this.state.hours && !isNaN(this.state.hours) ? (this.state.hours * rates[this.state.edit.turnaround_time || '2']) : 0
    const processingFee = Math.round((subtotal / .99 - subtotal) * 100) / 100;
    const total = subtotal + processingFee;

    return <Wrapper>
      <Modal
        width="350px"
        open={this.state.showChargeModal}
        onClose={() => this.setState({ showChargeModal: false })}
        closeOnOverlay={true}
      >
        <div className="chargeModal">
          <div>Organization: {this.state.org ? this.state.org.name : 'Loading...'}</div>
          <div>Show: {this.state.show ? this.state.show.name : 'Loading...'}</div>
          <div>Total hours: {this.state.hours}</div>
          <div>Subtotal: ${subtotal.toFixed(2)}</div>
          <div>Processing Fee: ${processingFee.toFixed(2)}</div>
          <div>Total charge: ${total.toFixed(2)}</div>
          <div className="chargeModalButtons">
            <Button onClick={this.performCharge}>
              Charge ${total.toFixed(2)}
            </Button>
            <Button onClick={() => this.setState({ showChargeModal: false })}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Header>
        <Header.Head bold huge>
          Edit Request #{this.state.editN || ''} for {this.state.show ? this.state.show.name : 'Loading...'}
        </Header.Head>
      </Header>
      <FormWrapper>
        <div className='topInfo'>
          <div className='topInfo1'>
            <div className="topInfoHeader">Contact information</div>
            <div>
              <div className="field">
                <span>Name:</span>
                <Input value={this.state.edit && this.state.edit.name ? this.state.edit.name : ''} />
              </div>
              <div className="field">
                <span>Phone:</span>
                <Input value={this.state.edit && this.state.edit.phone ? this.state.edit.phone : ''} />
              </div>
              <div className="field">
                <span>Email:</span>
                <Input type="email" value={this.state.edit && this.state.edit.email ? this.state.edit.email : ''} />
              </div>
            </div>
          </div>
          <div className='topInfo2'>
            <div className="topInfoHeader">Organization Information</div>
            <div>
              <div className="field">
                <span>Organization:</span>
                <Input value={this.state.org ? this.state.org.name : ''} disabled />
              </div>
              <div className="field">
                <span>Opening Date:</span>
                <Input value={this.state.booking ? this.state.booking.opening : ''} disabled />
              </div>
              <div className="field">
                <span>Account Number:</span>
                <Input value={this.state.org ? this.state.org.account_number : ''} disabled />
              </div>
            </div>
          </div>
          <div className='topInfo3'>
            <div><span>Turnaround Time:</span></div>
            {this.state.edit && this.state.edit.turnaround_time === '5' && (
              <div> 5 Business-day ($60/hour, $30 half hour minimum) </div>
            )}

            {this.state.edit && this.state.edit.turnaround_time === '2' && (
              <div> 2 Business-day ($120/hour) </div>
            )}
          </div>
          <div className='topInfo4'>
            <div><span>Status:</span></div>
            <div>
              <select onChange={this.handleChange} name="status" value={this.state.update && this.state.update.status ? this.state.update.status : ''}>
                <option checked={this.state.update && this.state.update.status === 'awaiting_processing'} value="awaiting_processing">Awaiting Processing</option>
                <option checked={this.state.update && this.state.update.status === 'received'} value="received">Received</option>
                <option checked={this.state.update && this.state.update.status === 'on_hold'} value="on_hold">On Hold</option>
                <option checked={this.state.update && this.state.update.status === 'complete'} value="complete">Complete</option>
                <option checked={this.state.update && this.state.update.status === 'cancelled'} value="cancelled">Cancelled</option>
              </select>
            </div>
          </div>
          <div className='topInfo4'>
            <div><span>Editor:</span></div>
            <div>
              <Input name="assigned_to" onChange={this.handleChange} value={this.state.update && this.state.update.assigned_to ? this.state.update.assigned_to : ''} />
            </div>
          </div>
          <div className='topInfo4'>
            <div className="updateButton" disabled={this.state.loading}>
              <Button onClick={this.updateClient}>Send status update email</Button>
            </div>
          </div>
          <div className='topInfo4'>
            <div className="updateButton" disabled={this.state.loading}>
              <Button 
                style={{
                  backgroundColor: '#d32f2f',
                  color: 'white'
                }}
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this entire edit request? This action cannot be undone.')) {
                    this.deleteEditRequest();
                  }
                }}
              >
                Delete Edit Request
              </Button>
            </div>
          </div>
        </div>

        <div className="editRequestBox">
          <Text large className="editsTitle">Editor Notes</Text>
          <div className="messageHistoryBox" style={{ marginBottom: '20px' }}>
            <div className="messagesDisplay" style={{ 
              maxHeight: '300px', 
              overflowY: 'auto', 
              padding: '10px',
              border: '1px solid #ddd',
              borderRadius: '4px',
              marginBottom: '10px',
              backgroundColor: '#f9f9f9'
            }}>
              {this.state.messages.length === 0 && (
                <div style={{ padding: '10px', color: '#666', fontStyle: 'italic' }}>
                  No messages yet. Start a conversation with the client below.
                </div>
              )}
              
              {this.state.messages.map(message => (
                <div 
                  key={message.id} 
                  style={{ 
                    marginBottom: '10px',
                    padding: '10px',
                    borderRadius: '4px',
                    backgroundColor: message.is_from_editor ? '#f9e3e3' : '#f5f5f5',
                    border: `1px solid ${message.is_from_editor ? '#e8c0c0' : '#e0e0e0'}`,
                  }}
                >
                  <div style={{ 
                    fontWeight: 'bold', 
                    fontSize: '14px',
                    marginBottom: '5px',
                    color: message.is_from_editor ? '#6e1406' : '#424242'
                  }}>
                    {message.is_from_editor ? 'Editor' : 'Client'} • {this.formatDate(message.created_at)}
                  </div>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{message.message}</div>
                </div>
              ))}
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <textarea
                value={this.state.newMessage}
                onChange={e => this.setState({ newMessage: e.target.value })}
                placeholder="Type a message to the client..."
                style={{ 
                  width: '100%',
                  minHeight: '100px',
                  padding: '10px',
                  marginBottom: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  resize: 'vertical'
                }}
              />
              <Button 
                onClick={this.handleSendMessage}
                style={{ 
                  alignSelf: 'flex-end', 
                  backgroundColor: '#6e1406',
                  color: 'white',
                  padding: '8px 16px'
                }}
              >
                Send Message
              </Button>
            </div>
          </div>
        </div>

        <div className="editRequestBox">
          <Text large className="editsTitle">Edit Requests</Text>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Cue #</th>
                  <th className="editRequestHeader">Edit Request</th>
                  <th className="editorCommentHeader">Editor Comments</th>
                </tr>
              </thead>
              <tbody id="editRows">
                {this.state.edit && this.state.edit.edits && Array.isArray(this.state.edit.edits) ? this.state.edit.edits.map((edit, ei) => {
                  // Create an array of elements to return
                  const elements = [];
                  
                  // Determine if track has current edits
                  const hasEdits = edit.edit && Array.isArray(edit.edit) && edit.edit.length > 0 && edit.edit[0] !== "";
                  
                  // Check if we're currently editing this empty track 
                  const isEditingEmpty = !hasEdits && this.state.editingEntry && this.state.editingEntry.startsWith(`${ei}-`);
                  
                  // Always add the track header row first
                  elements.push(
                    <tr key={`track-${edit.id}`} className="track-header-row">
                      <td>{edit.cue_number}</td>
                      <td style={{fontWeight: 'bold'}}>
                        {getTrackName(edit, ei)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        <Button 
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.addNewEdit(ei);
                          }}
                          style={{padding: '0 10px', fontSize: '0.8em', background: '#f5f5f5', color: '#333'}}
                        >
                          <Icon name="plus-1" style={{marginRight: '5px'}} />
                          Add Edit
                        </Button>
                      </td>
                    </tr>
                  );
                  
                  // Second element: previous edits (if they exist)
                  if (this.state.edits && Array.isArray(this.state.edits)) {
                    // Always attempt to show previous edits even for empty tracks
                    const completedEdits = [];
                    
                    (this.state.edits || [])
                      .filter(editRequest => editRequest.status === 'complete') // Only show completed edit requests
                      .forEach(editRequest => {
                        // Make sure edits is an array
                        let requestEdits = [];
                        if (editRequest.edits) {
                          if (typeof editRequest.edits === 'string') {
                            try {
                              const parsedEdits = JSON.parse(editRequest.edits);
                              if (Array.isArray(parsedEdits)) {
                                requestEdits = parsedEdits;
                              }
                            } catch (e) {
                              // Silent error for production
                            }
                          } else if (Array.isArray(editRequest.edits)) {
                            requestEdits = editRequest.edits;
                          }
                        }
                        
                        // Find edits for this track
                        
                        const trackEdits = requestEdits
                          .filter(localEdit => {
                            if (!localEdit) return false;
                            
                            // Try multiple matching strategies
                            // 1. Match by track_id (most reliable)
                            if (localEdit.track_id && edit.track_id && localEdit.track_id === edit.track_id) {
                              return true;
                            }
                            
                            // 2. Match by id
                            if (localEdit.id && edit.id && localEdit.id === edit.id) {
                              return true;
                            }
                            
                            // 3. Match by track_id to id or vice versa
                            if ((edit.track_id && localEdit.id === edit.track_id) || 
                                (localEdit.track_id && edit.id === localEdit.track_id)) {
                              return true;
                            }
                            
                            // 4. Track name match as fallback
                            if (localEdit.track_name && edit.track_name && 
                                localEdit.track_name.trim() === edit.track_name.trim()) {
                              return true;
                            }
                            
                            // 5. Cue number match as another fallback
                            if (localEdit.cue_number && edit.cue_number && 
                                localEdit.cue_number.trim() === edit.cue_number.trim()) {
                              return true;
                            }
                            
                            return false;
                          })
                          .map((trackEdit, i) => ({
                            key: `${editRequest.id}-${i}`,
                            trackEdit,
                            date: editRequest.created_at
                          }));
                        
                        // Add to our collection
                        completedEdits.push(...trackEdits);
                      });
                    
                    // Only add the row if there are actually completed edits for this track
                    if (completedEdits.length > 0) {
                      elements.push(
                        <tr key={`previous-edits-${edit.id}`}>
                          <td></td>
                          <td colSpan="2">
                            {completedEdits.map(({key, trackEdit, date}) => {
                                  return (
                                    <div
                                      key={key}
                                      style={{
                                        opacity: 0.8,
                                        marginBottom: '4px',
                                        padding: '4px 6px',
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '4px',
                                        border: '1px solid #e0e0e0',
                                        fontSize: '0.9em'
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: '0.8em',
                                          color: '#666',
                                          borderBottom: '1px dotted #ccc',
                                          paddingBottom: '2px',
                                          marginBottom: '3px'
                                        }}
                                      >
                                        <strong style={{color: '#444'}}>Completed {new Date(date).toLocaleDateString()}</strong>
                                      </div>
                                      {(() => {
                                        if (!trackEdit) {
                                          return null;
                                        }
                                        
                                        if (!trackEdit.edit) {
                                          return <div style={{color: 'red'}}>Error: No edit data found</div>;
                                        }
                                        
                                        // Handle different formats of edit data
                                        let editArray = [];
                                        
                                        if (Array.isArray(trackEdit.edit)) {
                                          editArray = trackEdit.edit;
                                        } else if (typeof trackEdit.edit === 'string') {
                                          // Single edit as string
                                          editArray = [trackEdit.edit];
                                        } else {
                                          return <div style={{color: 'red'}}>Error: Invalid edit data format</div>;
                                        }
                                        
                                        // Filter out empty edits
                                        const filteredEdits = editArray.filter(editText => 
                                          editText && typeof editText === 'string' && editText.trim() !== ''
                                        );
                                        
                                        if (filteredEdits.length === 0) {
                                          return <div style={{fontStyle: 'italic', color: '#888'}}>No edit details available</div>;
                                        }
                                        
                                        return filteredEdits.map((editText, editIndex) => (
                                          <div
                                            key={editIndex}
                                            style={{ 
                                              marginLeft: '4px',
                                              marginBottom: '2px',
                                              lineHeight: '1.2'
                                            }}
                                          >
                                            <span style={{color: '#555', marginRight: '4px'}}>•</span>{editText}
                                            {trackEdit.comments && trackEdit.comments[editIndex] && (
                                              <div
                                                style={{
                                                  fontSize: '0.8em',
                                                  color: '#666',
                                                  marginLeft: '12px',
                                                  marginTop: '1px',
                                                  fontStyle: 'italic'
                                                }}
                                              >
                                                <span style={{color: '#888'}}>Note:</span> {trackEdit.comments[editIndex]}
                                              </div>
                                            )}
                                          </div>
                                        ));
                                      })()}
                                    </div>
                                  );
                                })}
                          </td>
                        </tr>
                      );
                    }
                  }
                  // We intentionally don't return early here like before, to ensure previous edits are always displayed
                  
                  // Third element(s): current edits or add edit button
                  if ((edit.edit && Array.isArray(edit.edit) && edit.edit.length > 0) || isEditingEmpty) {
                    // Add edit rows
                    // Initialize edit array if needed for empty tracks
                    if (!edit.edit || !Array.isArray(edit.edit)) {
                      edit.edit = [''];
                    }
                    
                    if (Array.isArray(edit.edit)) {
                      // Make sure we have at least one edit item for editing
                      if (edit.edit.length === 0 && isEditingEmpty) {
                        edit.edit = [''];
                      }
                      
                      edit.edit.forEach((e, i) => {
                        // Don't skip empty edits if currently editing
                        const editing = this.state.editingEntry === `${ei}-${i}`;
                        if (!editing && (!e || e.trim() === '')) return; // Skip empty edits if not editing
                        
                        elements.push(
                          <tr key={`edit-${edit.id}-${i}`} className="edit-item-row">
                            <td>
                              <div className="editControls">
                                {editing ? (
                                  <Button onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.saveEditing(ei, i, edit);
                                  }}>Save</Button>
                                ) : (
                                    <Icon 
                                      name="pencil-1" 
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setEditing(ei, i, edit);
                                      }} 
                                    />
                                  )}
                                <input
                                  type="checkbox"
                                  checked={edit.checkbox && edit.checkbox[i]}
                                  onChange={() => this.triggerCheckbox(ei, i, edit)}
                                />
                                <Icon 
                                  name="bin"
                                  style={{
                                    color: '#d32f2f', 
                                    marginLeft: '8px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (window.confirm(`Are you sure you want to delete this edit? This action cannot be undone.`)) {
                                      this.deleteEdit(ei, i);
                                    }
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="editRequestItem">
                                {editing ?
                                  <textarea
                                    style={{
                                      width: '100%', 
                                      minHeight: '50px', 
                                      resize: 'vertical', 
                                      padding: '6px',
                                      fontSize: '0.95em',
                                      border: '1px solid #ccc',
                                      borderRadius: '3px'
                                    }}
                                    value={this.state.editNewEntry}
                                    onChange={e => this.setState({
                                      editNewEntry: e.target.value
                                    })} />
                                  : <span>{e}</span>
                                }
                              </div>
                            </td>
                            <td>
                              <div className="editorCommentItem">
                                {editing ? (
                                  <textarea
                                    style={{
                                      width: '100%', 
                                      minHeight: '50px', 
                                      resize: 'vertical', 
                                      padding: '6px',
                                      fontSize: '0.95em',
                                      border: '1px solid #ccc',
                                      borderRadius: '3px'
                                    }}
                                    placeholder="Add a comment"
                                    value={this.state.editorComment}
                                    onChange={e => this.setState({
                                      editorComment: e.target.value
                                    })} />
                                ) : (
                                    <div>{edit.comments && edit.comments[i] || ''}</div>
                                  )}
                              </div>
                            </td>
                          </tr>
                        );
                      });
                    }
                  }
                  
                  // Ensure previous edits are always visible by not returning early
                  return elements;
                }) : (
                  <tr>
                    <td colSpan="3" style={{textAlign: 'center', padding: '20px'}}>
                      No edits found or loading...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        
        <div className="updateButton" style={{maxWidth: '1300px', margin: '0 auto 1em', textAlign: 'center'}} disabled={this.state.loading}>
          <Button onClick={this.updateTop}>Save Changes</Button>
        </div>
        
        <div className="paymentBox">
          {this.state.edit.card_token ? (

            <div>
              <Text large>Payment method</Text>
              <div className="paymentNickname">
                {this.state.edit.card_nickname}
              </div>

              <div className="hoursForm">
                Hours:
              <Input className="hoursInput" type="number"
                  value={this.state.hours}
                  onChange={e => this.setState({ hours: e.target.value >= 0 ? e.target.value : 0 })} />
                <Button onClick={this.triggerCharge}>
                  Charge{' '}
                  {this.state.hours && !isNaN(this.state.hours) ? `$${(this.state.hours * rates[this.state.edit.turnaround_time || '2']).toFixed(2)}` : '$0.00'}
                </Button>
              </div>
            </div>
          ) : (
              <div className="noTokenDisclaimer">
                <div>Note:</div>
                <div>
                  This edit request does not contain a payment
                  method due to the organization having payment collection
                  disabled for edits at the time it was created.
                </div>
              </div>
            )}
        </div>
      </FormWrapper>
    </Wrapper>;
  }
}

