import React from 'react';
import { http } from 'data-graphql';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Header, FormWrapper, Text, Input, Button, TextArea } from 'components';

const Wrapper = styled.div`
  .table {
    display: block;
    max-width: 1300px;
    margin: 0.5em auto;
    padding: 15px 20px;
  }

  .table table {
    width: 100%;
    background: white;
    table-spacing: 0;
    border-collapse: collapse;
  }

  .table thead tr {
    background: #6e1406;
    color: white;
    text-align: left;
  }

  .topInfo {
    display: flex;
    flex-wrap: wrap;
    margin: 1em auto;
    max-width: 1300px;
  }

  .topInfo > div {
    width: 50%;
    padding: 1em;
  }

  .topInfo > div > div:first-child {
    margin-bottom: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
  }

  .topInfo .field {
    margin-bottom: 0.5em;
    text-align: left;
  }

  tr td {
    margin: 0;
    border: none;
    text-align: center;
  }

  .table tbody td {
    border-bottom: 1px solid #ccc;
  }

  .table tbody td:last-child {
    text-align: left;
  }

  .table th {
    padding: 0.4em;
  }

  .table tbody td {
    padding: 0.4em;
  }

  .table td:first-child {
    width: 100px;
    text-align: left;
  }

  .table td:nth-child(2) {
    width: calc(50% - 100px);
    padding-left: 1em;
  }

  .table td:last-child {
    width: 50%;
    text-align: left;
  }

  .table input {
    border: 1px solid #aaa;
  }

  input[disabled] {
    background-color: #ffe;
  }

  .radios > div {
    margin-bottom: 0.5em;
  }

  .radios input {
    margin-right: 0.5em;
  }

  .statusInfo {
    color: white;
    background: #6e1406;
    max-width: 300px;
    margin: 0 auto;
    padding: 12px 0;
  }

  .statusInfo .statusDraft {
    font-weight: bold;
    font-size: 18px;
  }

  .statusInfo > :last-child {
    font-weight: normal;
    font-size: 14px;
  }
  
  .statusInfo .statusDate {
    font-size: 14px;
    margin-top: 5px;
  }
  
  .statusInfo .chargeAmount {
    font-size: 16px;
    margin-top: 8px;
    font-weight: bold;
  }

  .editButtonHolder {
    text-align: center;
    padding: 12px 0;
    margin-bottom: 20px;
  }

  .editButtonHolder button {
    font-size: 18px;
    color: black;
    border-radius: 5px;
    padding: 8px 20px;
  }

  .editButtonHolder > div:last-child {
    color: #6e1406;
    text-align: center;
    max-width: 350px;
    margin: 12px auto;
    font-size: 14px;
  }
  
  .editorMessagesContainer {
    max-width: 1300px;
    margin: 20px auto;
    background-color: #fecb3f;
    padding: 20px;
    border-radius: 8px;
  }
  
  .editorMessagesContainer h3 {
    color: #8B0000;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  .messagesArea {
    display: flex;
  }
  
  .messagesDisplay {
    flex: 3;
    padding-right: 20px;
  }
  
  .userReplyArea {
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  
  .messageItem {
    background-color: white;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 15px;
    position: relative;
  }
  
  .messageItem.userMessage {
    background-color: #e0e0e0;
  }
  
  .messageHeader {
    font-size: 0.8em;
    margin-bottom: 8px;
    color: #666;
  }
  
  .messageContent {
    white-space: pre-wrap;
    word-break: break-word;
  }
  
  .userReplyArea textarea {
    flex-grow: 1;
    min-height: 150px;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  .userReplyArea button {
    align-self: flex-end;
    background-color: #8B0000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .userReplyArea button:hover {
    background-color: #a30000;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .modalContent h4 {
    margin-top: 0;
  }
  
  .modalContent button {
    background-color: #8B0000;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 4px;
    margin-top: 15px;
    cursor: pointer;
  }
`;

const statusLabels = {
  awaiting_processing: 'Awaiting Processing',
  received: 'Received',
  on_hold: 'On Hold',
  complete: 'Complete',
  cancelled: 'Cancelled'
};

export default class OrgEdits extends React.Component {
  constructor() {
    super();
    this.state = {
      show: {},
      edit: {},
      booking: {},
      userReply: '',
      messages: [],
      showModal: false
    };
  }

  getBookingEdits = async id => {
    http()
      .get(`/editing/list?booking_id=${id}`)
      .then(response => {
        if (response.success) {
          this.setState({ edits: response.items });
        }
      });
  };
  
  getEditMessages = async id => {
    try {
      const response = await http().get(`/editing/messages/${id}`);
      if (response.success) {
        this.setState({ messages: response.messages });
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  async componentDidMount() {
    await http()
      .get(`/editing/${this.props.id}`)
      .then(response => {
        if (!response.success) {
          return toast.error('Failed to retrieve edit');
        }
        this.getBookingEdits(response.booking.id);
        // Sort edits by cue number
        response.edit.edits.sort((a, b) =>
          a.cue_number.localeCompare(b.cue_number)
        );
        console.log({ response });
        this.setState(response);
        
        // Get messages
        this.getEditMessages(this.props.id);
      })
      .catch(error => {
        console.log(error);
        toast.error(error.message);
      });
  }
  
  handleUserReplyChange = (e) => {
    this.setState({ userReply: e.target.value });
  };
  
  handleSendReply = async () => {
    if (!this.state.userReply.trim()) {
      toast.error("Please enter a message before sending");
      return;
    }
    
    try {
      const response = await http().post('/editing/notify/user-response', {
        id: this.props.id,
        message: this.state.userReply
      });
      
      if (response.success) {
        // Add the message to the current state
        const newMessage = {
          id: Date.now(), // Temporary ID
          edit_id: this.props.id,
          message: this.state.userReply,
          is_from_editor: false,
          created_at: new Date().toISOString()
        };
        
        this.setState(prevState => ({
          messages: [...prevState.messages, newMessage],
          userReply: '',
          showModal: true
        }));
      } else {
        toast.error("Failed to send message");
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error("Failed to send message");
    }
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  
  formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  render() {
    if (
      !this.state.edit ||
      !this.state.edit.edits ||
      !this.state.booking ||
      !this.state.edits
    )
      return null;
    
    const showMessagingInterface = 
      this.state.edit.status === 'on_hold' && 
      (this.state.edit.editor_notes || this.state.messages.length > 0);
    
    return (
      <Wrapper>
        {!this.props.hideHeader && (
          <Header>
            <Header.Head bold huge>
              Edit Request #{this.state.editN} for {this.state.show.name}
            </Header.Head>
          </Header>
        )}
        <FormWrapper>
          <div className="topInfo">
            <div className="topInfo1">
              <div>Contact information</div>
              <div>
                <div className="field">
                  Name: <Input value={this.state.edit.name} />
                </div>
                <div className="field">
                  Phone: <Input value={this.state.edit.phone} />
                </div>
                <div className="field">
                  Email: <Input type="email" value={this.state.edit.email} />
                </div>
              </div>
            </div>
            <div className="topInfo2">
              <div>Organization Information</div>
              <div>
                <div className="field">
                  Organization: <Input value={this.state.org.name} disabled />
                </div>
                <div className="field">
                  Opening Date:{' '}
                  <Input
                    value={new Date(
                      this.state.booking.opening
                    ).toLocaleDateString()}
                    disabled
                  />
                </div>
                <div className="field">
                  Account Number:{' '}
                  <Input value={this.state.org.account_number} disabled />
                </div>
              </div>
            </div>
            <div className="topInfo3">
              <div>Turnaround Time</div>
              {this.state.edit.turnaround_time === '5' && (
                <div> 5 Business-day ($60/hour) </div>
              )}

              {this.state.edit.turnaround_time === '2' && (
                <div> 2 Business-day ($120/hour) </div>
              )}
            </div>
            <div className="topInfo4">
              <div className="statusInfo">
                <div>Status</div>
                <div>
                  {this.state.edit.draft ? (
                    <div className="statusDraft">NOT SUBMITTED</div>
                  ) : (
                    statusLabels[this.state.edit.status] ||
                    this.state.edit.status
                  )}
                </div>
                {this.state.edit.status_updated_at && (
                  <div className="statusDate">
                    {new Date(this.state.edit.status_updated_at).toLocaleDateString()}
                  </div>
                )}
                {this.state.edit.status === 'complete' && this.state.edit.charge_amount && (
                  <div className="chargeAmount">
                    Amount: ${parseFloat(this.state.edit.charge_amount).toFixed(2)}
                  </div>
                )}
              </div>
            </div>
          </div>

          {showMessagingInterface && (
            <div className="editorMessagesContainer">
              <h3>A message from your editor</h3>
              <div className="messagesArea">
                <div className="messagesDisplay">
                  {this.state.edit.editor_notes && this.state.messages.length === 0 && (
                    <div className="messageItem">
                      <div className="messageHeader">
                        Editor • {this.state.edit.status_updated_at ? 
                          this.formatDate(this.state.edit.status_updated_at) : 
                          'Date unknown'}
                      </div>
                      <div className="messageContent">{this.state.edit.editor_notes}</div>
                    </div>
                  )}
                  
                  {this.state.messages.map(message => (
                    <div 
                      key={message.id} 
                      className={`messageItem ${message.is_from_editor ? '' : 'userMessage'}`}
                    >
                      <div className="messageHeader">
                        {message.is_from_editor ? 'Editor' : 'You'} • {this.formatDate(message.created_at)}
                      </div>
                      <div className="messageContent">{message.message}</div>
                    </div>
                  ))}
                </div>
                
                <div className="userReplyArea">
                  <TextArea 
                    placeholder="Please reply here and then press send"
                    value={this.state.userReply}
                    onChange={this.handleUserReplyChange}
                  />
                  <Button onClick={this.handleSendReply}>Send</Button>
                </div>
              </div>
            </div>
          )}

          <Text large>Edits Requested</Text>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Cue #</th>
                  <th>Track Name</th>
                  <th>Edit Request</th>
                </tr>
              </thead>
              <tbody>
                {this.state.edit.edits.map(edit => {
                  return (
                    <tr key={edit.id}>
                      <td>{edit.cue_number}</td>
                      <td style={{ textAlign: 'left' }}>{edit.track_name}</td>
                      <td>
                        {this.state.edits &&
                          // Don't show completed edits for the current edit request - those will be in bold
                          this.state.edits
                            .filter(editRequest => 
                              editRequest.status === 'complete' && 
                              editRequest.id !== this.state.edit.id
                            ) 
                            .map(editRequest => {
                              // Try to parse the edits if they're a string
                              let parsedEdits = [];
                              if (typeof editRequest.edits === 'string') {
                                try {
                                  parsedEdits = JSON.parse(editRequest.edits);
                                } catch (e) {
                                  console.error("Error parsing edits:", e);
                                  parsedEdits = [];
                                }
                              } else if (Array.isArray(editRequest.edits)) {
                                parsedEdits = editRequest.edits;
                              }
                              
                              return parsedEdits
                                .filter(localEdit => 
                                  localEdit && localEdit.track_id === edit.track_id
                                )
                                .map((trackEdit, i) => {
                                  // Skip this track edit if no checkboxes are set
                                  if (!trackEdit.checkbox || 
                                      !Array.isArray(trackEdit.checkbox) ||
                                      !trackEdit.checkbox.some(checked => checked === true)) {
                                    return null;
                                  }
                                  
                                  return (
                                    <div
                                      key={`${editRequest.id}-${i}`}
                                      style={{
                                        opacity: 0.7,
                                        marginBottom: '8px',
                                        padding: '4px',
                                        backgroundColor: '#444444',
                                        color: '#ffffff',
                                        borderRadius: '4px'
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: '0.8em',
                                          color: '#dddddd'
                                        }}
                                      >
                                        Completed on {' '}
                                        {new Date(
                                          editRequest.created_at
                                        ).toLocaleDateString()}:
                                      </div>
                                      {trackEdit.edit && trackEdit.edit
                                        .filter((editText, idx) => 
                                          // Only show checked items that aren't empty
                                          trackEdit.checkbox && 
                                          trackEdit.checkbox[idx] === true && 
                                          editText && 
                                          editText.trim() !== ''
                                        )
                                        .map((editText, editIndex) => (
                                          <div
                                            key={editIndex}
                                            style={{ marginLeft: '8px' }}
                                          >
                                            • {editText}
                                            {trackEdit.comments &&
                                              trackEdit.comments[editIndex] && 
                                              trackEdit.comments[editIndex].trim() !== '' && (
                                                <div
                                                  style={{
                                                    fontSize: '0.8em',
                                                    color: '#cccccc',
                                                    marginLeft: '16px'
                                                  }}
                                                >
                                                  Comment:{' '}
                                                  {trackEdit.comments[editIndex]}
                                                </div>
                                              )}
                                          </div>
                                        ))
                                      }
                                    </div>
                                  );
                                })
                                .filter(Boolean) // Remove null items
                            })}
                        <ul style={{ fontWeight: 'bold' }}>
                          {edit.edit
                            .filter(e => e?.trim().length > 0)
                            .map((e, i) => (
                              <li key={i}>
                                {e}
                                {edit.comments && edit.comments[i] && edit.comments[i].trim() !== '' && (
                                  <div
                                    style={{
                                      backgroundColor: '#fecb3f',
                                      padding: '8px',
                                      borderRadius: '4px',
                                      margin: '5px 0 8px 0',
                                      fontSize: '0.9em'
                                    }}
                                  >
                                    {edit.comments[i]}
                                  </div>
                                )}
                              </li>
                            ))}
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </FormWrapper>
        {this.state.edit.status === 'awaiting_processing' && (
          <div className="editButtonHolder">
            <div>
              <Button
                onClick={() => {
                  window.location.href = `/org/edits/${this.state.booking.id}/edit/${this.props.id}`;
                }}
              >
                {this.state.edit.draft
                  ? 'Click Here to Edit or Submit your Draft'
                  : 'Click Here to Edit your Submission'}
              </Button>
            </div>
            <div>
              You can add or make changes to your submission until it is marked
              as "Received". After that, any further changes must be submitted
              as an additional edit request.
            </div>
          </div>
        )}
        
        {this.state.showModal && (
          <div className="modal">
            <div className="modalContent">
              <h4>Message Sent</h4>
              <button onClick={this.closeModal}>OK</button>
            </div>
          </div>
        )}
      </Wrapper>
    );
  }
}